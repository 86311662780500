/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, Heading, jsx, Text, Themed} from 'theme-ui'
import React from "react"
import {Layout} from '../layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import {SectionGray, SectionImage, SectionWhite} from '../layout/section'

const PartnersAndProjects = () => (
  <Layout>
    <SectionImage image={(
      <StaticImage
        sx={{
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        layout="fullWidth"
        src="../images/digital-world.jpg"
        alt=""/>
    )}>
      <Themed.h1 sx={{
        color: 'white',
        marginBottom: ['2rem', '1rem'],
        fontWeight: 'regular',
      }}>
        A glimpse at the impact.
      </Themed.h1>

      <div sx={{
        maxWidth: '30rem',
        fontWeight: 'regular',
      }}>
        Some of the partners, projects, and results that illustrate the impact we’ve made on acclaimed organizations.
      </div>
    </SectionImage>

    <SectionGray>
      <TitledImage
        title={
          <React.Fragment>
            <Box sx={{display: ['none', 'block']}}>Army Software Factory</Box>
            <Box sx={{display: ['block', 'none']}}>Army<br/>Software<br/>Factory</Box>
          </React.Fragment>
        }
        image={(
          <StaticImage
            sx={{
              flexBasis: 0,
              flexGrow: 1,
            }}
            placeholder="tracedSVG"
            src="../images/army-software-factory.png"
            alt=""/>
        )}/>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}>
        <TitledParagraph
          title="The Upshot"
          text="We’re helping Army Futures Command build an organic Soldier-led software development organization that transforms how the Army delivers software capabilities to a 1+ million-person workforce.
          "/>
        <TitledParagraph
          title="The What"
          text="ATX Defense consultants are on-site providing critical organizational design guidance, internal and external stakeholder management, liaison, and technical integration services to the Army Software Factory. We are actively navigating the complexities and nuances of implementing modern processes that enable software factory success while satisfying (and improving upon) more traditional Army practices and requirements. Our ability to educate, communicate, and translate ensures maximum stakeholder buy-in, enables wide adoption of modern best practices, and paves the way for increased utilization of software factories across the DoD—where uniformed service members build high-impact software applications faster and better than traditional DoD contractors."/>
        <TitledParagraph
          title="The How"
          text="In addition to its unique experience and access to major DoD entities in the state of Texas, ATX Defense boasts a robust network of partners and allies within Austin’s tech industry. We have leveraged these connections to implement a scalable engagement model—allowing companies of all sizes, from Fortune 100 to small startups, to provide professional development and other training for Soldiers. Our vast experience in process improvement, technology integration, data analytics, and other management tools allow us to connect the dots in a full-scale organizational transformation. We’ve created dozens of custom business applications for the Army Software Factory built on Google Workspace and worked with its leadership to identify and define the key metrics and measure in real-time to empower leadership in making data-driven decisions."/>
      </Box>
    </SectionGray>

    <SectionWhite>
      <TitledImage
        title="CUI Compliance for Google Workspace"
        image={(
          <StaticImage
            placeholder="tracedSVG"
            src="../images/accountability-bot.tif"
            alt=""/>
        )}/>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}>
        <TitledParagraph
          title="The Upshot"
          text="We were the first company to deliver a version of Google Workspace capable of handling Controlled Unclassified Information (CUI) to the Department of Defense, solving the previously intractable problem of secure collaboration on personal devices using industry-leading technology."/>
        <TitledParagraph
          title="The What"
          text={`The Department of Defense requires seamless and reliable tools for mission-critical collaboration, but existing solutions are plagued with performance, interoperability and security issues. This has resulted in unacceptable downtime and the use of "shadow IT" for sensitive data. Hundreds of millions of people across the world use Google's superior collaboration and productivity tools, but until recently it was not possible for DoD users to process, store, or transmit sensitive content known as Controlled Unclassified Information (CUI). In June 2021, ATX Defense won the first DoD contract for a CUI-capable Google Workspace, resulting in a radical increase in morale and productivity.`}/>
        <TitledParagraph
          title="The How"
          text="In addition to providing Google Workspace, ATX Defense delivers custom tools built within Google Workspace and seamlessly integrated them with our clients' current tech stack. This unlocked real-time collaboration, personal device use, secure file sharing, and several other solutions to needs that were impossible to meet using DoD enterprise solutions. For example, we designed and deployed an AI-powered chatbot to allow users to log their data and perform other services from their personal cell phones—including requesting time off, reporting duty status to supervisors in real-time, and giving feedback to peers. We also overhauled a public-facing HR application process and created a tailored Customer Relationship Management (CRM) solution within Google Workspace, saving a client hundreds of thousands of dollars in CRM licensing and integration fees."/>
      </Box>
    </SectionWhite>
  </Layout>
)

type TitledImageProps = {
  title: React.ReactNode
  image: React.ReactElement
}

const TitledImage = ({title, image}: TitledImageProps) => (
  <Box sx={{
    marginBottom: '4rem',
    display: 'flex',
    flexDirection: ['column', 'row'],
    gap: '2rem',
    alignItems: ['flex-start', 'center'],
  }}>
    <Themed.h1 sx={{
      fontWeight: 'heavy',
      flexBasis: 0,
      flexGrow: 1,
    }}>
      {title}
    </Themed.h1>
    <Box sx={{
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: ['20rem', 'initial'],
    }}>
      {image}
    </Box>
  </Box>
)

type TitledParagraphProps = {
  title: string
  text: string
}

const TitledParagraph = ({title, text}: TitledParagraphProps) => (
  <Box>
    <Themed.h4 sx={{color: 'atxOrange', fontWeight: 'bold', mb: '1rem'}}>{title}</Themed.h4>
    <Box sx={{fontWeight: 'medium'}}>{text}</Box>
  </Box>
)

export default PartnersAndProjects
